/**
 * Copyright ©2022 Drivepoint
 */


// enable Segment for TypeScript:
/// <reference types="@types/segment-analytics" />
declare global {
  interface Window {analytics: any}
}

import {Config} from "@bainbridge-growth/node-frontend";
import ITelemetryProvider from "./ITelemetryProvider";
import ServiceRegistry from "../ServiceRegistry";
import State from "../state/State";
import BainbridgeUser from "../user/BainbridgeUser";
import Telemetry from "@services/telemetry/Telemetry";

/**
 * Segment-specific functionality.
 */

export default class Segment implements ITelemetryProvider {

  get name(): string { return "segment"; }

  async embed(): Promise<void> {
    const script = document.createElement("script");
    script.innerHTML = `
      !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="TRLH0J93t3gOH2aXh5KscZ6DamG5AimX";;analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("${Config.get("segment.id")}");
        analytics.page();
      }}();
    `;
    document.head.append(script);
  }

  async identify(): Promise<void> {
    if (!window.analytics) { return; }
    let payload: any;
    const user = Telemetry.user?.user;
    const uid = user ? `${user.uid}-${Config.get("environment")}` : undefined;
    payload = {
      email: user?.email,
      id: uid,
      environment: Config.get("environment"),
      webapp_version: Config.get("version"),
      company: Telemetry.company?.id
    };
    if (uid) {
      window.analytics.identify(uid, payload);
    } else {
      window.analytics.identify(payload);
    }
  }

  async track(event: string, data: any = {}): Promise<void> {
    if (!window.analytics) { return; }
    logger.trace("track", event, data);
    const user: BainbridgeUser = State.get("user");
    const uid = user?.user?.uid ? `${user.user.uid}-${Config.get("environment")}` : undefined;
    const payload = {
      ...data,
      email: user?.user?.email,
      id: uid,
      environment: Config.get("environment"),
      webapp_version: Config.get("version"),
      company: this.company
    };
    window.analytics.track(event, payload);
  }

  get company(): string | undefined {
    const user: BainbridgeUser = State.get("user");
    return ServiceRegistry.companyService.company?.id || user?.excelUser?.excelCompanyId;
  }

}
