/**
 * Copyright ©2022 Drivepoint
 */

import React, {Suspense, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, ThemeProvider} from "@mui/material";
import useThemeContext from "@hooks/useThemeContext";
import Routing from "../Routing/Routing";
import NavigationDrawer from "../navigation/NavigationDrawer/NavigationDrawer";
import TopAppBar from "../TopAppBar";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import VersionUpdatedButton from "../../widgets/component/VersionUpdatedButton";
import UserBox from "../../widgets/component/UserBox/UserBox";
import Suspended from "../../widgets/component/Suspended";
import DPNotifications from "@components/DPNotifications/DPNotifications";
import BreadcrumbManager from "@services/BreadcrumbManager";
import usePage from "@hooks/usePage";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";
import {FirebaseAuthenticationState} from "@services/firebase/Firebase";
import "./App.less";
import DPErrorBoundary from "../DPErrorBoundary";

export default function App(): any {

  const {themeProvider, ThemeContext, theme} = useThemeContext();
  const page = usePage();
  const user = useStateChange<BainbridgeUser>("user");
  const authentication = useStateChange<FirebaseAuthenticationState>("authentication");
  const navigate = useNavigate();

  const [navigationOpen, setNavigationOpen] = useState<boolean>(getNavigationDrawerState());

  useEffect(() => {
    setNavigationDrawerState();
  }, [navigationOpen]);

  function getNavigationDrawerState(): boolean {
    const state = localStorage.getItem("navigationDrawerState");
    return state != null ? JSON.parse(state) : true;
  }

  function setNavigationDrawerState(): void {
    localStorage.setItem("navigationDrawerState", JSON.stringify(navigationOpen));
  }

  function toggleNavigationDrawer(): void {
    setNavigationOpen(!navigationOpen);
  }

  function renderAppBar(): any {
    if (page.current?.hideChrome) { return; }
    return <TopAppBar open={navigationOpen}>
      <Breadcrumbs ref={BreadcrumbManager.ref} />
      <Box sx={{flexGrow: 1}} />
      <VersionUpdatedButton />
      <UserBox />
    </TopAppBar>;
  }

  function renderNavigationDrawer(): any {
    if (page.current?.hideChrome) { return; }
    return <NavigationDrawer open={navigationOpen} toggle={toggleNavigationDrawer} resizable />;
  }

  if (authentication === FirebaseAuthenticationState.AUTHENTICATED && !user?.companies?.length && !page.current?.public) {
    navigate("/quickstart", {replace: true});
    return;
  }

  return <DPErrorBoundary fallback={<p>Here will be error component</p>}>
    <ThemeContext.Provider value={themeProvider}>
      <ThemeProvider theme={theme}>
        <main id="main" className="app desktop">
          {renderNavigationDrawer()}
          <section className="app-content">
            {renderAppBar()}
            <Suspense fallback={<Suspended />}>
              <Routing />
            </Suspense>
          </section>
        </main>
        <DPNotifications />
      </ThemeProvider>
    </ThemeContext.Provider>
  </DPErrorBoundary>;

}
