/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";

//COMPONENTS
import Page from "./Page";
import PageContainer from "./PageContainer";
import BrandLink from "../widgets/styled/BrandLink";

//HOOKS
import {useNavigate} from "react-router-dom";

//UTILS
import ServiceRegistry from "../services/ServiceRegistry";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";

export default function NotFoundPage(props: any): any {

  const navigate = useNavigate();
  const user = useStateChange<BainbridgeUser>("user");

  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    setShow(false);
    const timeout = setTimeout(() => { setShow(true); }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function home(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    const defaultRoutePath = ServiceRegistry.routeService.pages.find(page => page.default)?.path;
    const currentCompanyId = ServiceRegistry.companyService.company?.id ?? user?.excelUser?.excelCompanyId;
    navigate(`${defaultRoutePath ? defaultRoutePath.replace(":company", currentCompanyId) : "/"}`);
  }

  function render(): any {
    if (!show) { return; }
    const deprecatedReportsPatterns = [
      /\/[^/]+\/analytics\/ltv/,
      /\/[^/]+\/analytics\/retention/,
      /\/[^/]+\/dashboards\/performance_vs_plan/
    ];
    const isDeprecated = deprecatedReportsPatterns.some(dr => dr.test(location.pathname));
    if (isDeprecated) {
      return <Typography>
        This report got moved. Navigate to <BrandLink onClick={home}>reports</BrandLink> and search for any available report.
      </Typography>;
    }
    return <Typography>
      We're sorry, that page could not be found. Click <BrandLink onClick={home}>here</BrandLink> to go to the home page.
    </Typography>;
  }

  return <Page>
    <PageContainer>
      {render()}
    </PageContainer>
  </Page>;

}
