/**
 * Copyright ©2022 Drivepoint
 */

import LRU from "lru-cache";
import {Config} from "@bainbridge-growth/node-frontend";
import WebAppServerError from "../../errors/WebAppServerError";
import Firebase from "../firebase/Firebase";

export default class WebAppServerClient {

  private static _cache?: any = new LRU({max: 20, ttl: 60000});

  static clear(): void {
    WebAppServerClient._cache.clear();
  }

  static async getStandardOptions(method: string, body?: any): Promise<any> {
    const token = await Firebase.auth.currentUser?.getIdToken();
    if (body !== undefined) {
      body = typeof body === "string" ? body : JSON.stringify(body);
    }
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      method: method,
      body: body
    };
  }

  static async request(path: string, method: string, body?: any, abortController?: AbortController): Promise<any> {
    const options = await this.getStandardOptions(method, body);
    options.signal = abortController?.signal;
    return await fetch(`${Config.get("server.api.url", window.location.origin)}${path}`, options)
      .then(async (response: any) => {
        if (response.status !== 200) { throw new WebAppServerError(response.statusText, path, await response.json(), response.status); }
        return response.json();
      });
  }

  static async signUp(data: any): Promise<any> {
    return this.request("/ui/auth/sign-up", "POST", data);
  }

  static async inviteCompany(data: any): Promise<any> {
    return this.request("/ui/company/invite", "POST", data);
  }

  static async sendForgotPassword(data: any): Promise<any> {
    return this.request("/ui/auth/forgot-password", "POST", data);
  }

  static async clearPasswordReset(data: any): Promise<void> {
    return this.request("/ui/auth/clear-reset", "POST", data);
  }

  static async inviteUser(companyId: string, data: any): Promise<any> {
    return this.request(`/ui/company/${companyId}/invite-user`, "POST", data);
  }

  static async resendUserInvite(companyId: string, data: any): Promise<any> {
    return this.request(`/ui/company/${companyId}/resend-user-invitation`, "POST", data);
  }

  static async removeUserInvite(companyId: string, id: string): Promise<any> {
    return this.request(`/ui/company/${companyId}/user-invitation/${id}`, "DELETE");
  }

  static async getUrl(url: string): Promise<any> {
    const options = await this.getStandardOptions("GET");
    return fetch(url, options)
      .then(async (response: any) => {
        const data = await response.json();
        if (response.status !== 200) { throw new Error(data.error); }
        return data;
      });
  }

  static async getModelSchema(model: string): Promise<any> {
    return this.request(`/ui/${model}/MODEL-SCHEMA`, "GET");
  }

  static async listModels(model: string, all: boolean = false): Promise<any> {
    return this.request(`/ui/${model}?all=${all}`, "GET");
  }

  static async createModel(model: string, data: any): Promise<any> {
    return this.request(`/ui/${model}`, "POST", data);
  }

  static async updateModel(model: string, data: any): Promise<any> {
    return this.request(`/ui/${model}/${data.id}`, "PATCH", data);
  }

  static async deleteModel(model: string, data: any): Promise<any> {
    return this.request(`/ui/${model}/${data.id}`, "DELETE", data);
  }

  static async createCompanyPage(companyId: string, page: any): Promise<any> {
    return this.request(`/ui/navigationPage/${companyId}`, "POST", page);
  }

  static async updateCompanyPage(companyId: string, page: any): Promise<any> {
    return this.request(`/ui/navigationPage/${companyId}/${page.id}`, "PATCH", page);
  }

  static async deleteCompanyPage(companyId: string, pageId: string): Promise<any> {
    return this.request(`/ui/navigationPage/${companyId}/${pageId}`, "DELETE");
  }

  static async connectIntuit(companyId: string): Promise<any> {
    return this.request(`/ui/intuit/connect/${companyId}`, "GET");
  }

  static async disconnectIntuit(companyId: string): Promise<any> {
    return this.request(`/ui/intuit/disconnect/${companyId}`, "GET");
  }

  static async requestIntuitDisconnect(data: any): Promise<any> {
    return this.request("/ui/intuit/disconnect_request", "POST", data);
  }

  static async connectAmazonSP(companyId: string, redirectUri: string): Promise<any> {
    return this.request(`/ui/amazonSP/connect/${companyId}`, "POST", {redirectUri});
  }

  static async requestAmazonSPRefreshToken(companyId: string, oAuthCode: string, sellingPartnerId: string): Promise<any> {
    return this.request(`/ui/amazonSP/requestRefreshToken/${companyId}`, "POST", {oAuthCode, sellingPartnerId});
  }

  static async connectNetsuite(companyId: string, data: object): Promise<any> {
    return this.request(`/ui/netsuite/connect/${companyId}`, "POST", data);
  }

  static async refreshData(companyId: string, data?: object): Promise<any> {
    return this.request(`/api/airflow/dags/${companyId}/run`, "POST", data);
  }

  static async getLastRefreshDataInfo(companyId: string, dataSourceId?: string): Promise<any> {
    return this.request(`/api/airflow/dags/${companyId}/lastRun/${dataSourceId}`, "GET");
  }

  static async getLastCompletedRefreshDataInfo(companyId: string, dataSourceId = "ALL"): Promise<any> {
    return this.request(`/api/airflow/dags/${companyId}/lastCompletedRun/${dataSourceId}`, "GET");
  }
}
