/**
 * Copyright ©2024 Drivepoint
 */

import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import compareVersions from "compare-versions";
import {Config, useStore} from "@bainbridge-growth/node-frontend";
import ThemedDefaultButton from "../../widgets/styled/ThemedDefaultButton";
import {EventBus} from "@services/eventbus/EventBus";
import ClientStatusStore from "@services/store/ClientStatusStore";
import "./AboutDialog.css";

export type AboutDialogProps = {
};

export type AboutDialogInterface = {
  show: () => void;
};

const AboutDialog = forwardRef<AboutDialogInterface, AboutDialogProps>(({}: AboutDialogProps, ref: ForwardedRef<AboutDialogInterface>): any => {

  useImperativeHandle(ref, (): AboutDialogInterface => ({
    show: () => {
      setOpen(true);
    }
  }));

  const [clientStatus] = useStore<any, ClientStatusStore>(ClientStatusStore);

  const [open, setOpen] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<any>();

  useEffect(() => {
    const registration = EventBus.register("server:status", setServerStatus);
    return () => {
      EventBus.unregister(registration);
    };
  }, []);

  function renderProductVersion(name: string, value: any, hash: string): any {
    return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <div style={{width: "8rem", textAlign: "right", marginRight: "1rem", color: "#404040"}}>{name}:</div>
      <div style={{width: "5rem"}}>{value}</div>
      <div style={{fontSize: "0.75rem", color: "#808080", marginLeft: "1rem"}}>{hash}</div>
    </div>;
  }

  function renderClientStatus(): any {
    if (!clientStatus?.version) { return; }
    if (compareVersions(clientStatus.version, Config.get("version")) <= 0) { return; }
    return renderProductVersion("Latest Web App", `${clientStatus?.version ? `v${clientStatus.version}` : "(N/A)"}`, clientStatus?.hash ? clientStatus.hash : "(N/A)");
  }

  return <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth={true}>
    <DialogTitle sx={{userSelect: "none"}}>About</DialogTitle>
    <DialogContent>
      <div style={{display: "flex", flexDirection: "column"}}>
        {renderProductVersion("Web App", `v${Config.get("version")}`, Config.get("hash"))}
        {renderClientStatus()}
        {renderProductVersion("Server", `${serverStatus?.version ? `v${serverStatus.version}` : "(N/A)"}`, serverStatus?.hash ? serverStatus.hash : "(N/A)")}
      </div>
      <div style={{fontSize: "0.75em", textAlign: "center", paddingTop: "1em", color: "#808080"}}>
        Copyright &copy;2021-{new Date().getFullYear()} Drivepoint
      </div>
    </DialogContent>
    <DialogActions>
      <ThemedDefaultButton onClick={() => setOpen(false)}>Done</ThemedDefaultButton>
    </DialogActions>
  </Dialog>;

});

export default AboutDialog;
