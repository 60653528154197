/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import ServiceRegistry from "../services/ServiceRegistry";
import useStateChange from "../hooks/useStateChange";
import BainbridgeUser from "../services/user/BainbridgeUser";
import useRoutes from "../hooks/useRoutes";
import useReady from "../hooks/useReady";
import {Box, CircularProgress} from "@mui/material";
import Page from "./Page";
import useQueryParams from "../hooks/useQueryParams";
import jwtDecode from "jwt-decode";
import WebAppServerClient from "../services/clients/WebAppServerClient";

export default function DefaultPage(props: any): any {

  const navigate = useNavigate();
  const user = useStateChange<BainbridgeUser>("user");
  const ready = useReady();
  const routes = useRoutes();
  const queryParams = useQueryParams();

  useEffect(() => {
    if (!ready) { return; }
    navigateToUserDefaultPage();
  }, [user, ready, routes]);

  async function handleShopifyCallback(companyId: string, cb: any): Promise<void> {
    try {
      await WebAppServerClient.request(`/ui/company/${cb.company.id}/shopify/connect`, "PATCH", {shop: cb.shop});
    } catch (error: any) {
      logger.error(error.message, companyId, cb);
    }
  }

  async function handleCallback(companyId: string): Promise<void> {
    if (!queryParams.has("cb")) { return; }
    let cb: any = queryParams.get("cb");
    try {
      cb = jwtDecode(cb);
      if (cb.source === "shopify") {
        await handleShopifyCallback(companyId, cb);
      }
    } catch (error: any) {
      logger.error(error.message, companyId, cb);
    }
  }

  async function navigateToUserDefaultPage(): Promise<void> {
    const companyId = user?.excelUser?.excelCompanyId;

    if (companyId) {
      await handleCallback(companyId);
      ServiceRegistry.companyService.setCompanyById(companyId);
      const page = ServiceRegistry.routeService.defaultPage;
      if (page) {
        let path = page.path;
        const tokens = ServiceRegistry.routeService.getPathTokens(path);
        for (const token of tokens) {
          switch (token) {
            case ":company":
              path = path.replace(token, companyId);
              break;
          }
        }
        navigate(path);
      }
    }
  }

  return <Page>
    <Box sx={{display: "flex", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  </Page>;

}
